import React from 'react';
import { getProducts, ProductCodeFilter } from 'util/hasProductCode';
import { useSuspenseQuery } from '@apollo/client';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';
import mapValues from 'lodash/fp/mapValues';
import {
  GetProductCollectionDocument,
  GetProductCollectionQuery,
} from '__generated__/graphql';
import { AssetsData } from 'types';

type ProductCollectionItems = NonNullable<
  GetProductCollectionQuery['contentfulKnowHowProductCollection']
>['items'];

const remapToSlugs = flow(
  keyBy('productCode'),
  mapValues(
    (product: NonNullable<ProductCollectionItems>[number]) =>
      product!.practiceAreaCollection?.items.map(
        item => (item as { slug: string }).slug,
      ) ?? [],
  ),
);

export function useProductCollectionSlugs(
  assets: AssetsData,
  productCode: ProductCodeFilter,
) {
  const { data } = useSuspenseQuery(GetProductCollectionDocument);

  return React.useMemo(() => {
    if (assets) {
      const availableProducts = getProducts(assets, productCode, false);

      const productSlugs = remapToSlugs(
        data?.contentfulKnowHowProductCollection!.items,
      );

      return availableProducts
        .map(item => productSlugs[item!.productCode!])
        .filter(Boolean)
        .reduce((t, v) => t.concat(v), []);
    }
  }, [assets, productCode, data]);
}
