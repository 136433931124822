import React from 'react';
import classSet from 'classnames';
import { useToggle } from 'hooks/useToggle';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import {
  PRIMARY_LAW_FILTER_GUIDE,
  PrimaryLawFilterGuideHelpButton,
} from './PrimaryLawFilterGuideHelpButton';

const expandableCache: Record<string, boolean> = {};

export interface ExpandableSectionProps {
  disabled?: boolean;
  children: React.ReactNode;
  border?: boolean;
  arrowRight?: boolean;
  name: string;
  defaultExpanded?: boolean;
  cache?: boolean;
  className?: string;
}

export const ExpandableSection = ({
  disabled,
  children,
  border = true,
  arrowRight = false,
  name,
  defaultExpanded = false,
  cache = false,
  className,
}: ExpandableSectionProps) => {
  const mouseUp = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) =>
      (e.target as HTMLButtonElement).blur(),
    [],
  );
  const [expanded, toggle] = useToggle(
    expandableCache[name] || defaultExpanded,
  );

  const toggleExpanded = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        if (cache) {
          expandableCache[name] = !expanded;
        }
        toggle(e);
      }
    },
    [toggle, disabled, cache, expanded, name],
  );

  return (
    <StyledExpandableSection
      className={classSet({
        'filter-section': true,
        border,
        disabled,
        'arrow-right': arrowRight,
        [className!]: !!className,
      })}
    >
      <h4>
        <button
          onClick={toggleExpanded}
          onMouseUp={mouseUp}
          aria-expanded={expanded}
          aria-disabled={disabled}
        >
          <i
            className={classSet({
              fas: true,
              'fa-caret-down': expanded,
              'fa-caret-left': !expanded && !arrowRight,
              'fa-caret-right': !expanded && arrowRight,
            })}
          />
          {name}
          {PRIMARY_LAW_FILTER_GUIDE[name] && (
            <PrimaryLawFilterGuideHelpButton />
          )}
        </button>
      </h4>
      <div role="listbox">{expanded && children}</div>
    </StyledExpandableSection>
  );
};

const StyledExpandableSection = styled.div.attrs(props => ({
  ...props,
  sidebarPadding: 25,
}))`
  color: ${CEB_COLOR('BLACK')};
  font-family: 'Zilla Slab', serif;
  flex: 0 1 auto;
  &.border {
    border-top: 1px solid ${CEB_COLOR('ALTO')};
  }
  &.disabled {
    opacity: 0.6;
  }
  &.arrow-right {
    h4 button i {
      float: none;
      margin-right: 0.2em;
      width: 0.8em;
      vertical-align: -1px;
    }
  }
  > h4 {
    font-size: 15px;
    .disabled & {
      color: gray;
    }
    button {
      display: block;
      color: inherit;
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      width: 100%;
      padding: 18px ${({ sidebarPadding }) => sidebarPadding}px;
      text-align: left;
      cursor: pointer;
      &:focus {
        outline: 1px solid ${CEB_COLOR_RGBA('DANUBE', 0.6)};
      }
      > i {
        float: right;
      }
    }
  }
  > div > div + button {
    margin: 18px 0 18px 25px;
  }
`;
