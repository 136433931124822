import React from 'react';

export function usePrintHandlers(
  beforePrint: () => void,
  afterPrint?: () => void,
) {
  const [isPrinting, setPrinting] = React.useState<boolean>(false);
  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      let mqListener, printQuery;
      if (window.matchMedia) {
        printQuery = window.matchMedia('print');
        mqListener = function (mql: MediaQueryListEvent) {
          if (mql.matches) {
            beforePrint && beforePrint();
            setPrinting(true);
          } else {
            afterPrint && afterPrint();
            setPrinting(false);
          }
        };
        printQuery.addEventListener('change', mqListener);
      }
      window.addEventListener('beforeprint', beforePrint);
      afterPrint && window.addEventListener('afterprint', afterPrint);
      return () => {
        if (window.matchMedia) {
          printQuery.removeEventListener('change', mqListener);
        }
        window.removeEventListener('beforeprint', beforePrint);
        afterPrint && window.removeEventListener('afterprint', afterPrint);
      };
    }
  });
  return [isPrinting];
}
