import React from 'react';
import { NavLink as Link, LinkProps, useLocation } from 'react-router-dom';
import { TabContext } from 'components/app-context/TabContextProvider';

export interface TrackedLinkProps extends LinkProps {
  disabled?: boolean;
  state?: Record<string, unknown>;
}

export function TrackedLink({
  children,
  onClick,
  disabled,
  state,
  ...props
}: TrackedLinkProps) {
  const location = useLocation();

  const { dispatch } = React.useContext(TabContext) || {};
  const { tabState } = (location.state as any) || {};

  const handleLinkClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        event.preventDefault();
        return false;
      }
      if (onClick) onClick(event);
      if (event.ctrlKey) return true;
      if (event.defaultPrevented) return;
      dispatch?.({
        type: 'MINIMIZE_TAB',
      });
      return true;
    },
    [dispatch, onClick, disabled],
  );

  return (
    <Link
      {...props}
      state={{
        ...(state || {}),
        tabState,
        from: location,
      }}
      onClick={handleLinkClick}
    >
      {children}
    </Link>
  );
}
