import React from 'react';
import toElement from 'util/toElement';
import { nodeListToArray } from 'util/nodeListToArray';
import { useLocation } from 'react-router-dom';
import { wrapTables } from 'util/wrapTables';
import { applyFocusedClass } from 'util/applyFocusedClass';
import { orBlock } from 'pages/practitioner/document/orBlock';

export function useContentDocument(documentContentStringified: string) {
  const location = useLocation();

  return React.useMemo(() => {
    const contentEl = toElement(documentContentStringified);

    const altClauses = nodeListToArray(
      contentEl.querySelectorAll('[type="alternate-clauses"]'),
    );
    altClauses.map(altEl => altEl?.appendChild(orBlock?.cloneNode(true)));

    wrapTables(
      contentEl,
      'table:not([style="caption"]):not([style="signature"]):not([wrapped])',
    );
    applyFocusedClass(contentEl, location.hash);

    return { contentEl };
  }, [documentContentStringified, location]);
}
